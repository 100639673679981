import React, { useMemo } from 'react';
import { Formik } from 'formik';
import HypotheekTotalen from '../../../hypotheek/hypotheek-totalen/hypotheek-totalen';
import { useRequestInit, LoadingSpinner } from 'adviesbox-shared';
import { useFetchData } from '../../../shared/components/dossier/dossier-wijzig-adviseur-modal/usefetchdata';
import {
  HypotheekHuidigeSituatieOutput,
  HypotheekVoorstelOutput
} from '../../../.generated/forms/formstypes';
import { mapHypotheekDlToUi } from '../../../hypotheek/infra/map-hypotheek-dl-2-ui';
import { HypothekenState } from '../../../hypotheek/infra/hypotheek-types';

export const TotalenWrapper: React.FC = () => {
  const { params, settings, requestInit } = useRequestInit();

  const createUrl = useMemo(() => {
    const baseUrl = settings.klantdossiersFormsOrigin;
    return params.voorstel
      ? `${baseUrl}/Voorstellen/${params.voorstel}/Hypotheek`
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/Hypotheek`;
  }, [params, settings]);

  const { loading, data, error } = useFetchData<HypotheekHuidigeSituatieOutput | HypotheekVoorstelOutput>(
    createUrl,
    requestInit
  );

  if (loading) return <LoadingSpinner />;
  if (error || !data || !data.hypotheken) return <div>Geen hypotheek gegevens beschikbaar.</div>;

  const hypotheekKey = Object.keys(data.hypotheken)[0];
  const hypotheek: HypothekenState | null = hypotheekKey ? mapHypotheekDlToUi(hypotheekKey, data) : null;

  if (!hypotheek) return <div>Geen hypotheek gegevens beschikbaar.</div>;

  return (
    <Formik onSubmit={/* istanbul ignore next */ () => {}} initialValues={hypotheek} enableReinitialize>
      <HypotheekTotalen situatie={params.voorstel ? 'voorstel' : 'huidig'} selected={0} />
    </Formik>
  );
};
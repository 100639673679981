import React, { ReactElement } from "react";
import { Formik, Form } from "formik";
import { LabeledCurrencyInput, LabeledTextInput } from "adviesbox-shared";
import { Lasten } from "../../../.generated/rapportage/rapportagetypes";

type LastenOverzichtCardProps = {
  lasten: Lasten;
};

export type LastenOverzichtValues = {
  rente: number | null;
  aflossing: number | null;
  totaleBruto: number | null;
  fiscaal: number | null;
  totaleNetto: number | null;
  restantSchuld: number | null;
  waardeOpbouw: number | null;
  toelichting: string | null;
};

const LastenOverzichtCard = ({ lasten }: LastenOverzichtCardProps): ReactElement => {
  const mapLastenToOverzicht = (lasten: Lasten): LastenOverzichtValues => {
    return {
      rente: lasten.rente,
      aflossing: (lasten.hypotheekAflossing || 0) + (lasten.rente || 0),
      totaleBruto: lasten.brutoLast,
      fiscaal: lasten.fiscaalVoordeel,
      totaleNetto: lasten.nettoLast,
      restantSchuld: lasten.somHypotheken,
      waardeOpbouw: lasten.waardeopbouw,
      toelichting: "n.v.t."
    };
  };

  return (
    <Formik
      enableReinitialize
      initialValues={mapLastenToOverzicht(lasten)}
      onSubmit={/* istanbul ignore next */ _values => {}}
    >
      <Form>
        <div className="row">
          <div className="col-6">
            <h3 className="px-3 py-2">Maandlasten</h3>
            <LabeledCurrencyInput name="rente" caption="Rente" readonly />
            <LabeledCurrencyInput name="aflossing" caption="Aflossing" readonly />
            <LabeledCurrencyInput name="totaleBruto" caption="Totale bruto maandlast" readonly />
            <LabeledCurrencyInput name="fiscaal" caption="Fiscaal voordeel" readonly />
            <LabeledCurrencyInput name="totaleNetto" caption="Totale netto maandlast" readonly />
          </div>
          <div className="col-6">
            <h3 className="px-3 py-2">Schuld en waardeopbouw</h3>
            <LabeledCurrencyInput name="restantSchuld" caption="Restant schuld" readonly />
            <LabeledCurrencyInput name="waardeOpbouw" caption="Waardeopbouw" readonly />

            <h3 className="px-3 py-4">Toelichting</h3>
            <LabeledTextInput name="toelichting" caption="Toelichting" readonly />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default LastenOverzichtCard;

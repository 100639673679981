/* istanbul ignore file */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useRequestInit, sleep, LoadingSpinner } from "adviesbox-shared";

export const fetchData = async <T,>(url: string, requestInit: RequestInit, setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<T> => {
  await sleep(3000);
  setLoading(true);
  const response = await fetch(url, requestInit);

  if (!response.ok) throw new Error("Network response was not ok");
  return response.json();
};

export const Loading: React.FC = () => <div>Loading...</div>;

interface DataDisplayProps<T> {
  endpoint?: string;
  render: (data: T) => React.ReactNode;
  isVoorstel?: boolean;
  method?: string | undefined
}

export const DataDisplay = <T,>({ endpoint, render, isVoorstel, method }: DataDisplayProps<T>): React.ReactElement => {
  const { settings, params, requestInit } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();

  const getDashboardUrl = useMemo(() => {
    const baseUrl = `${settings.klantdossiersFormsOrigin}`;
    return isVoorstel 
      ? `${baseUrl}/Voorstellen/${params.voorstel}/Dashboard/${endpoint}` 
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/Dashboard/${endpoint}`;
  }, [params.adviesdossier, params.voorstel, isVoorstel, endpoint, settings.klantdossiersFormsOrigin]);

  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchData<T>(
      endpoint?.toLowerCase().startsWith('http') ? endpoint : getDashboardUrl,
      { ...requestInit, method: method || "GET" },
      setLoading
    )
      .then(result => {
        setData(result);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, [endpoint, getDashboardUrl, requestInit, method]);

  if (loading) return <LoadingSpinner size="S" />;
  if (error) return <div>Er is een fout opgetreden bij het ophalen van de gegevens.</div>;
  return <>{data ? render(data) : null}</>;
};
import React, { useMemo } from "react";
import { LineChart, useRequestInit, LoadingSpinner, bedragFormat } from "adviesbox-shared";
import { useFetchData } from "../../../shared/components/dossier/dossier-wijzig-adviseur-modal/usefetchdata";
import { LastenOverzicht, Lasten } from "../../../.generated/rapportage/rapportagetypes";

type LastenOverzichtChartProps = {
  onHoverCallback?: (jaarGegevens: Lasten) => void;
  onClickCallback?: (jaarGegevens: Lasten) => void;
};

const LastenOverzichtChart: React.FC<LastenOverzichtChartProps> = ({  onHoverCallback, onClickCallback }: LastenOverzichtChartProps) => {
  const { settings, params, requestInit } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();

  const getLastenOverzichtUrl = useMemo(() => {
    const baseUrl = `${settings.rapportageOrigin}`;
    return params.voorstel
      ? `${baseUrl}/Voorstellen/${params.voorstel}/SnelInzicht/LastenOverzicht`
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/SnelInzicht/LastenOverzicht`;
  }, [settings.rapportageOrigin, params.adviesdossier, params.voorstel]);

  const fetchConfig = useMemo(
    () => ({
      url: getLastenOverzichtUrl,
      config: { ...requestInit, method: "POST" }
    }),
    [getLastenOverzichtUrl, requestInit]
  );

  const { data, loading, error } = useFetchData<LastenOverzicht>(fetchConfig.url, fetchConfig.config);

  if (error) return <div>Error: {error}</div>;
  if (!data || loading) return <LoadingSpinner />;
  if (!data.jaarGegevens?.length) return <div>Geen lastenoverzicht gegevens beschikbaar</div>;

  return (
    <div className="p-2" data-testid="line-chart">
      <LineChart
        labels={data.jaarGegevens.map(c => c.jaar.toString())}
        optionsObject={{
          layout: {
            padding: 20
          },
          parsing: {
            xAxisKey: "id",
            yAxisKey: "value"
          },
          scales: {
            y: {
              ticks: {
                callback: /* istanbul ignore next */ (val: any, _index: any) => {
                  return bedragFormat(val);
                },
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              align: "start",
              labels: {
                boxPadding: 16,
                usePointStyle: true,
                boxHeight: 8,
                boxWidth: 8,
              }
            },
            tooltip: {
              callbacks: {
                label: /* istanbul ignore next */ (tooltipItem: any) => {
                  return " " + bedragFormat(tooltipItem.dataset.data[tooltipItem.dataIndex].value, 0);
                },
              }
            },
          },
          onHover: /* istanbul ignore next */ (e: any, items: any) => {
            if (items.length && onHoverCallback) {
              const year = items[0].element.$context.raw.id;
              const jaarGegevens = data.jaarGegevens?.find(c => c.jaar === year);
              if (jaarGegevens) onHoverCallback(jaarGegevens);
            }
          },
          onClick: /* istanbul ignore next */ (e: any, items: any) => {
            if (items.length && onClickCallback) {
              const year = items[0].element.$context.raw.id;
              const jaarGegevens = data.jaarGegevens?.find(c => c.jaar === year);
              if (jaarGegevens) onClickCallback(jaarGegevens);
            }
          }
        }}
        datasets={[
          {
            label: "Bruto maandlasten",
            data: data.jaarGegevens.map(c => {
              return { id: c.jaar, value: c.brutoLast };
            }),
            backgroundColor: "#DF2935",
            borderColor: "#DF2935",
            borderWidth: 3,
            radius: 0
          },
          {
            label: "Netto maandlasten",
            backgroundColor: "#C2F970",
            borderColor: "#C2F970",
            data: data.jaarGegevens.map(c => {
              return { id: c.jaar, value: c.nettoLast };
            }),
            borderWidth: 3,
            radius: 0
          }
        ]}
      />
    </div>
  );
};

export default LastenOverzichtChart;

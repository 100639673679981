import React, { ReactElement } from "react";
import { Card, LineChart, bedragFormat } from "adviesbox-shared";
import { PeriodeUitwerkingType, AcceptatieState } from "../infra/acceptatie-schema";
import { useFormikContext } from "formik";
import "./acceptatie-chart.scss";

interface MonthData {
  month: number;
  year: number;
  beschikbareRuimte: number | null;
  fictieveMaandlast: number | null;
  werkelijkeMaandlast: number | null;
  toegestandeLast: number | null;
}

const generateDatasetFromPeriods = (periods: PeriodeUitwerkingType[]): MonthData[] => {
  const monthsMap = new Map<string, MonthData>();

  periods.forEach(period => {
    /* istanbul ignore next */
    if (!period.startDatum || !period.eindDatum) return;

    let currentDate = period.startDatum;
    const endDate = period.eindDatum;

    while (!currentDate.isAfter(endDate)) {
      const key = `${currentDate.year()}-${currentDate.monthValue()}`;
      const existingData = monthsMap.get(key);

      /* istanbul ignore next */
      if (!existingData) {
        monthsMap.set(key, {
          month: currentDate.month().value(),
          year: currentDate.year(),
          beschikbareRuimte: period.beschikbareRuimte,
          fictieveMaandlast: period.totaleMaandlastVoorstel,
          werkelijkeMaandlast: period.werkelijkeMaandlast,
          toegestandeLast: period.toegestaneMaandlast
        });
      }

      currentDate = currentDate.plusMonths(1);
    }
  });

  return Array.from(monthsMap.values()).sort((a, b) => {
    return a.year - b.year || a.month - b.month;
  });
};

export const AcceptatieChart = (): ReactElement => {
  const { values } = useFormikContext<AcceptatieState>();
  const dataset = generateDatasetFromPeriods(values.specificatieIndicatieMeerMinder.uitwerkingen);

  return (
    <>
      <div className="acceptatie-chart">
        <Card title="Ontwikkeling toetslast">
          <div className="acceptatie-chart__chart">
            <LineChart
              datasets={[
                {
                  label: "Fictieve maandlast",
                  data: dataset.map(/* istanbul ignore next */ c => { return { id: c.year, value: c.fictieveMaandlast, month: c.month } }),
                  fill: true,
                  backgroundColor: "rgb(11,132,165,0.2)",
                  borderColor: "rgba(11,132,165)",
                  borderWidth: 3,
                  radius: 0
                },
                {
                  label: "Werkelijke maandlast",
                  data: dataset.map(/* istanbul ignore next */ c => { return { id: c.year, value: c.werkelijkeMaandlast, month: c.month } }),
                  fill: true,
                  backgroundColor: "rgba(88,80,141,0.2)",
                  borderColor: "rgba(88,80,141)",
                  borderWidth: 3,
                  radius: 0,
                  hidden: true
                },
                {
                  label: "Toegestane last",
                  data: dataset.map(/* istanbul ignore next */ c => { return { id: c.year, value: c.toegestandeLast, month: c.month } }),
                  backgroundColor: "rgba(246,200,95,0.2)",
                  borderColor: "rgb(246,200,95)",
                  borderWidth: 3,
                  radius: 0
                },
              ]}
              labels={dataset.map(/* istanbul ignore next */ c => `${c.year}`)}
              optionsObject={{
                parsing: {
                  xAxisKey: 'id',
                  yAxisKey: 'value'
                },
                plugins: {
                  legend: {
                    display: true,
                    align: "start",
                    labels: {
                      boxPadding: 16,
                      usePointStyle: true,
                      boxHeight: 8,
                      boxWidth: 8
                    }
                  },
                  tooltip: {
                    displayColors: false,
                    callbacks: {
                      title: /* istanbul ignore next */ (tooltipItems: any) => {
                        const object = tooltipItems[0].dataset.data[tooltipItems[0].dataIndex];
                        return object.month + "/" + object.id;
                      },
                      label: /* istanbul ignore next */ (tooltipItem: any) => {
                        return `${tooltipItem.dataset.label}: ${bedragFormat(tooltipItem.dataset.data[tooltipItem.dataIndex].value, 0)}`;
                      },
                      afterBody: /* istanbul ignore next */ (tooltipItems: any) => {
                        return `Ruimte: ${bedragFormat(tooltipItems[0].parsed.y - tooltipItems[1].parsed.y, 0)}`;
                      },
                    }
                  }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: {
                    ticks: {
                      callback: /* istanbul ignore next */ function(val: any, index: any) {
                        return index % 12 === 0 ? dataset[index].year : "";
                      }
                    }
                  },
                  y: {
                    display: true,
                    min: 0,
                    ticks: {
                      callback: /* istanbul ignore next */ (val: any, _index: any) => {
                        return bedragFormat(val);
                      }
                    }
                  }
                }
              }}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

import React, { useContext, useRef, useEffect, ReactElement } from "react";
import { DashboardDataContext, NewDashboardType } from "../new-dashboard-context";
import { AcceptatieAjax } from "../hypotheek-acceptatie/acceptatie/acceptatie-ajax";
import classNames from "classnames";
import "./new-dashboard-container.scss";
import { Button } from "react-bootstrap";
import { Icon } from "adviesbox-shared";
import { SnelInzichtDrawer } from "../snel-inzicht-drawer/snel-inzicht-drawer";
import { TotalenWrapper } from "../hypotheek-totalen/totalen-wrapper";

export const NewDashboardContainerComponent = (): ReactElement => {
  const { open, setOpen, type } = useContext(DashboardDataContext);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    if (open) {
      containerRef?.current.removeAttribute("inert");
      return;
    }

    containerRef?.current.setAttribute("inert", "");
  }, [open]);

  return (
    <div
      ref={containerRef}
      data-testid="dashboard-container"
      className={classNames("dashboard-container__content", {
        "dashboard-container__content--open": open,
        "dashboard-container__content--closed": !open
      })}
    >
      <TotalenWrapper/>
      <div className="dashboard-container__actions">
        <Button data-testid="dashboard-container-close" onClick={() => setOpen(false)}>
          <Icon name="close" alt="Sluiten" iconSize={"m"} />
        </Button>
      </div>
      {/* <div className="dashboard-container__header">{ type }</div> */}
      {type === NewDashboardType.SnelInzicht && <SnelInzichtDrawer />}
      {type === NewDashboardType.HypotheekAcceptatie && <AcceptatieAjax />}
    </div>
  );
};

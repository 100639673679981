import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import { useRequestInit, bedragFormat, LoadingSpinner } from "adviesbox-shared";
import { useFetchData } from "../../../shared/components/dossier/dossier-wijzig-adviseur-modal/usefetchdata";
import { LastenOverzicht, Lasten, Totalen } from "../../../.generated/rapportage/rapportagetypes";

type ColumnConfig = {
  headerTitle: string;
  isCurrency: boolean;
};

const lastenOverzichtColumnConfig: Record<string, ColumnConfig> = {
  jaar: { headerTitle: "Jaar", isCurrency: false },
  leeftijdAanvrager: { headerTitle: "Leeftijd aanvrager", isCurrency: false },
  leeftijdPartner: { headerTitle: "Leeftijd partner", isCurrency: false },
  brutoInkomen: { headerTitle: "Bruto inkomen", isCurrency: true },
  waardeopbouw: { headerTitle: "Waardeopbouw", isCurrency: true },
  somHypotheken: { headerTitle: "Som hypotheken", isCurrency: true },
  rente: { headerTitle: "Rente", isCurrency: true },
  hypotheekAflossing: { headerTitle: "Hypotheek aflossing", isCurrency: true },
  uitkeringKapitaalverzekeringen: { headerTitle: "Uitkering kapitaalverzekeringen", isCurrency: true },
  verzekeringspremies: { headerTitle: "Verzekeringspremies", isCurrency: true },
  spaarEnBeleggingsdepots: { headerTitle: "Spaar- en beleggingsdepots", isCurrency: true },
  opnameUitDepot: { headerTitle: "Opname uit depot", isCurrency: true },
  kredietlast: { headerTitle: "Kredietlast", isCurrency: true },
  overigeUitgaven: { headerTitle: "Overige uitgaven", isCurrency: true },
  huur: { headerTitle: "Huur", isCurrency: true },
  brutoLast: { headerTitle: "Bruto Last", isCurrency: true },
  fiscaalVoordeel: { headerTitle: "Fiscaal voordeel", isCurrency: true },
  vrh: { headerTitle: "VRH", isCurrency: true },
  nettoLast: { headerTitle: "Netto last", isCurrency: true }
};


const LastenOverzichtTable: React.FC = () => {
  const { settings, params, requestInit } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();

  const getLastenOverzichtUrl = useMemo(() => {
    const baseUrl = `${settings.rapportageOrigin}`;
    return params.voorstel
      ? `${baseUrl}/Voorstellen/${params.voorstel}/SnelInzicht/LastenOverzicht`
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/SnelInzicht/LastenOverzicht`;
  }, [settings.rapportageOrigin, params.adviesdossier, params.voorstel]);

  const fetchConfig = useMemo(
    () => ({
      url: getLastenOverzichtUrl,
      config: { ...requestInit, method: "POST" }
    }),
    [getLastenOverzichtUrl, requestInit]
  );

  const { data, loading, error } = useFetchData<LastenOverzicht>(fetchConfig.url, fetchConfig.config);

  // Helper function to format values
  const formatValue = (value: number | null, col: string): string | number | Record<string, ColumnConfig>  => {
    if (value === null) return "";
    return lastenOverzichtColumnConfig[col].isCurrency ? bedragFormat(value) : value;
  };

  if (error) return <div>Error: {error}</div>;
  if (!data || loading) return <LoadingSpinner />;
  if (!data.jaarGegevens?.length) return <div>Geen lastenoverzicht gegevens beschikbaar</div>;

  const { jaarGegevens, totalen } = data;

  if (!jaarGegevens || jaarGegevens.length === 0 || !totalen)
    return <>Te weinig gegevens beschikbaar voor volledig overzicht.</>;

  // Filter columns with data in either jaarGegevens or totalen
  const columnsWithData = Object.keys(lastenOverzichtColumnConfig).filter(
    col =>
      jaarGegevens.some(jaarData => jaarData[col as keyof Lasten] !== null && !!jaarData[col as keyof Lasten]) ||
      (totalen[col as keyof Totalen] != null && !!totalen[col as keyof Totalen])
  );

  return (
    <div className="lastenoverzicht" data-testid="lastenoverzicht">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {columnsWithData.map(col => (
              <th key={col}>{lastenOverzichtColumnConfig[col].headerTitle}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {jaarGegevens.map((jaarData: Lasten) => (
            <tr key={jaarData.jaar}>
              {columnsWithData.map(/* istanbul ignore next */ col => (
                <td key={col}>{formatValue(jaarData[col as keyof Lasten] as number | null, col)}</td>
              ))}
            </tr>
          ))}
          <tr>
            {columnsWithData.map(/* istanbul ignore next */ (col, index) => (
              <td key={col}>
                {index === 0 ? "Totaal" : formatValue(totalen[col as keyof Totalen] as number | null, col)}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default LastenOverzichtTable;

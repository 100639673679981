import React, { ReactElement, Suspense, useMemo } from "react";
import { Loading, DataDisplay } from "../data-display/data-display";
import { useRequestInit, bedragFormat } from "adviesbox-shared";
import { LastenOverzicht } from "../../../.generated/rapportage/rapportagetypes";

export const SnelInzichtResultaat = ({ onClick, situatie }: { onClick: () => void, situatie: string }): ReactElement => {
  const {settings, params} = useRequestInit();

  /* istanbul ignore next */
  const getRapportageLastenoverzicht = useMemo(() => {
    const baseUrl = `${settings.rapportageOrigin}`;
    return params.voorstel ? `${baseUrl}/Voorstellen/${params.voorstel}/SnelInzicht/LastenOverzicht?alleenEersteJaar=true` 
        : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/SnelInzicht/LastenOverzicht?alleenEersteJaar=true`
  }, [ params.adviesdossier, params.voorstel, settings.rapportageOrigin]);

  return (
    <Suspense fallback={<Loading />}>
      <button type="button" className="new-dashboard__entry-btn" data-testid="button-testid" onClick={onClick}>
        <h2>SNEL INZICHT</h2>
        <DataDisplay
          endpoint={getRapportageLastenoverzicht}
          method="POST"
          render={(data: LastenOverzicht) => {
            /* istanbul ignore next */
            if (!data.jaarGegevens?.length) return <div>Er is een fout opgetreden bij het ophalen van de gegevens.</div>;

            const eersteJaar = data.jaarGegevens.pop()
            
            return eersteJaar ? (
            <>
              <div>Bruto {bedragFormat(eersteJaar?.brutoLast, 0)} p.m.</div>
              <div>Netto {bedragFormat(eersteJaar?.nettoLast, 0)} p.m.</div>
            </>) : /* istanbul ignore next */ <></>
          }}
        />
      </button>
    </Suspense>
  );
};
